import { Global, css } from "@emotion/react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Carousel from 'react-bootstrap/Carousel'

import * as styles from "../styles/home.module.css"
import 'bootstrap/dist/css/bootstrap.min.css'


export default function Home({ data }) {
  const { allFile } = data
  const images = allFile.edges
  return (
    <Layout logo='index'>
      
      <Global
        styles={css`
          * {
            background: #f0d4c2;
            color: #d36736;
          }
          nav a {
            color: #d36736;
          }
       `}
      />
      <section className={ styles.header }>
        {/* <GlobalSetter color='#d36736' background='#f0d4c2' /> */}
        <Carousel>
            {/* {images.map(image => (<CarouselItem key={ image.node.id } overviewItem={ image.node } />))} */}
            {images.map(image => (
              <Carousel.Item key = { image.node.id }>
                < GatsbyImage image={getImage(image.node)} alt={image.node.name} />
              </Carousel.Item>
            ))}
        </Carousel>
      </section>
    </Layout>
  )
}


export const query = graphql`
  query HomepageImages {
    allFile(
      filter: {sourceInstanceName: {eq: "imagesOverview"}}
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData (
              height: 700
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }  
`
